<template>
	<div class="metope-detail">
		<mall-banner 
			:page-position='metopeDetail.type + "/" + type'
			router-name="前往图文制作" 
			router-path="/media">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="selectedType"
                    :select-options="filterType"
                    @handleSearch="handleSearch">
                </search-component>
            </template>
		</mall-banner>
		<div class="container">
			<detail-info
				:price="showPrice"
				:detail-info="metopeDetail"
				:img-list="metopeImages"
				:owner-info="metopeOwner"
				:datum="datum"
				other-route="metopeDetail"
				@handleClick="handleAddOrder">
				<template #contentLf>
					<img-list
                        :md-img="mdImg"
                        :img-list="metopeImages"
                        @change-md="handleMdChange">
                    </img-list>
				</template>
				<template #specs v-if="showSpec">
					<div v-for="(item, index) in specList"
						:key="index">
						<spec-data
							v-model="selectedSpec[index]"
							:spec-name="item.label"
							:spec-list="item.tags"
							@change="handleSpec">
						</spec-data>
					</div>
				</template>
				<template #setSize>
					<div class="rt-setsize">
						<!-- <template v-if="metopeDetail.quantityOn"> -->
						<template v-if="metopeDetail.sizeOn==1">
							<el-radio
								v-model="need_size"
								label="0">
								我不清楚墙面尺寸
							</el-radio>
							<span class="rt-mark"
								v-if="need_size=='0'">
								（勾选此项，平台将会派专人免费上门量房）
							</span>
							<el-radio 
								v-model="need_size"
								label="1">
								我清楚墙面尺寸，尺寸为
							</el-radio>
							<div class="rt-size">
								<el-input
									v-model="height"
									type="number"
									:min="0"
									size="small"
									placeholder="长度"
									class="rt-input"
									:disabled="need_size=='0'">
								</el-input>
								<span>x</span>
								<el-input
									v-model="width"
									type="number"
									:min="0"
									size="small"
									placeholder="宽度"
									class="rt-input"
									:disabled="need_size=='0'">
								</el-input>
								<span>cm</span>
							</div>
						</template>
						<div class="rt-num" v-if="metopeDetail.sizeOn">
							<span>购买数量：</span>
							<el-input
								v-model="quantity"
								type="number"
								:min="1"
								class="num-input"
								size="small"
								placeholder="请输入数量">
							</el-input>
						</div>
					</div>
				</template>
			</detail-info>
			<detail-tabs
				:detail-content="metopeDetail.detail">
			</detail-tabs>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapActions } from "vuex";

	export default {
		components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
			mallBanner: () => import("@/components/makingMall/mallBanner"),
			detailInfo: () => import('@/components/makingMall/detailInfo'),
			imgList: () => import('@/components/detailImgList'),
        	detailTabs: ()=> import('@/components/detailTabs'),
			specData: () => import('@/components/makingMall/specData')
		},
		data() {
			return {
				search: '',
				mdImg: '',
				selectedType: '0',
				selectedSpec: [],
				price: '',
				need_size: '0',
				size: '',
				width: '',
				height: '',
				quantity:1,
				priceNum:'',
				posTprice:'',
				datum:{},
				type:""
			}
		},
		computed: {
			...mapState({
				is_login: state => state.user.is_login,
				user_id: state => state.user.user_id,
				metopeDetail: state => state.metope.metopeDetail,
				metopeImages: state => state.metope.metopeImages,
				metopeOwner: state => state.metope.metopeOwner,
				specList: state => state.metope.specList,
				priceList: state => state.metope.priceList,
				filterType: state => state.metope.filterType
			}),
			showSpec(){
				let list = this.specList;
				return JSON.stringify(list) == '[]'? false: true;
			},
			needSize() {
				let needSize = this.metopeDetail.quantityOn && this.width && this.height
				return Boolean(needSize);
			},
			needNum() {
				return Boolean(this.metopeDetail.sizeOn && this.quantity);
			},
			//价格
			showPrice() {
				let price = Number(this.price);
				// 不清楚尺寸 
				if(this.need_size == '0') {
					let probablePrice = " "
  					   if(this.selectedSpec.length != this.specList.length){
						  probablePrice = this.metopeDetail.price;
					   }else{
						  probablePrice = this.price * this.quantity
					   }
					   return probablePrice;
				}
				// 计算面积
				if(this.needSize) {
					price = Math.ceil(Number(this.height)* Number(this.width)/ 10000)* price;
				}
				
				if(this.needNum) {
					price = Number(this.quantity)* price;
			
				}				
				return this.posTprice = price
			}
		},
		watch: {
			metopeImages: {
				handler: function(value) {
					if(value.length > 0){
						this.mdImg = value[0];
					}
				},
				deep: true
			},
			metopeDetail(){
				// console.log(this.metopeDetail,"测试")
			}
		
		},
		methods: {
			...mapMutations([
				'setMetopeFilters',
				'setMetopeDetail'
			]),
			...mapActions([
				'getMetopeFilters',
				'getMetopeDetail',
				'handleAddMetopeOrder'
			]),
			handleSearch({ search, type }){
				sessionStorage.setItem('search', search);
				sessionStorage.setItem('type', type);
                this.selectedType = type;
				if(this.search !== search){
					this.search = search;
					this.$router.push({
						name: 'metopeList'
					})
				}
			},
			handleMdChange(mdImg, index) {
				this.mdImg = mdImg;
			},
			handleSpec(){
				let price = this.priceList;
				price.map((item, index) => {
					if(JSON.stringify(item.spec) == JSON.stringify(this.selectedSpec)){
						this.price = item.price;
					}
				})
			},
			handleAddOrder(){
				let count = 0;
				// 判断是否登录
				if(!this.is_login){
					this.$message({
						showClose: true,
						message: '请先登录',
						type: 'warning'
					})
					return
				}
				// 判断是否选择规格
				if(this.selectedSpec.length != this.specList.length){
					this.$message({
						showClose: true,
						message: '请选择规格',
						type: 'warning'
					})
					return
				}
				// 判断是否输入尺寸
				if(this.need_size == 1 && (!this.width || !this.height)) {
					this.$message({
						showClose: true,
						message: '请输入尺寸',
						type: 'warning'
					})
					return
				}
				
				let size = this.need_size == 0? 0: (this.width + '*' + this.height);
				// console.log("初始值",this.price,"计算后的数值",this.posTprice)
				// 请求
				this.handleAddMetopeOrder({
					userID: this.user_id,
					id: this.metopeDetail.id,
					count:this.quantity,
					size,
					spec1: this.selectedSpec[0],
					spec2: this.selectedSpec[1] || '',
					price: this.posTprice?this.posTprice:this.showPrice
					
				}).then(res => {
					this.$message({
						showClose: true,
						message: res.msg,
						type: res.type? 'success': 'error'
					})
					res.type && this.$router.push({
						name: 'metopePay',
						query: {
							id: res.orderID
						}
					})
				}).catch(err => {})
			}
		},
		beforeRouteEnter (to, from, next) {
			next(vm => {
				vm.getMetopeFilters();
				vm.getMetopeDetail(to.query.id);
				vm.datum = to.query.datum
				// console.log("测试是不是你",to.query.type)
				vm.type = to.query.type.label||"全部"
				
				
			})
			// this.type = to.query.type.label?to.query.type.label:"全部"
		},
		beforeRouteUpdate(to, from, next) {
			this.getMetopeFilters();
			this.getMetopeDetail(to.query.id);
			next();
		},
		beforeRouteLeave (to, from, next) {
            this.setMetopeFilters({});
			this.setMetopeDetail({});
			next();
		}
	};
</script>

<style scoped>
.blue {
	color: rgb(17, 66, 168);
	font-size: 18px;
}
.metope-detail {
	--color: #1f3eaa;
	background-color: rgb(250, 250, 250);
}
.container {
	padding-top: 20px;
}
.rt-setsize {
	display: flex;
	flex-direction: column;
}
.rt-mark {
	margin-bottom: 10px;
	color: #C0C4CC;
}
.rt-size {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	padding: 0 30px;
}
.rt-input {
    width: 100px;
}
.rt-num {
	display: flex;
	align-items: center;
	margin-top: 20px;
}
.num-input {
	width: 200px;
}
</style>
